<template>
  <div>
    <ed-campo :key="$root.$session.versao" />
  </div>
</template>

<script>
import EdCampo from '@/components/cadastro/formularioPersonalizado/editar'

export default {
  name: "pageCadastroEmpresa",
  props: {},
  components: {EdCampo },
  mounted() {},
  created() {},
  data() {
    return {
    };
  },
  methods: {
  
  },
};
</script>